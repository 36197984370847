import React, { useEffect, useState } from "react";
import { baseUrl } from '../shared';
import Login from '../components/login'
import FirstTab from "../components/tab-components/FirstTab";
import SecondTab from "../components/tab-components/SecondTab";
import './App.css';
import Search from "../components/search";
import Lookup from "../components/lookup";
import CreateBooking from "./create/createBooking";
import Edit from './edit/editItem';
import EditBooking from "./edit/editBooking";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TableHead } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString.toLowerCase());
    var itemNoUrl = urlParams.get('item')
    var bookingUrl = urlParams.get('booking')
      console.log('Item No: ', itemNoUrl);

function ItemView() {
  const [item, setItem] = useState();
  const [editing, setEditing] = useState(false);
  const [bookingView, setBookingView] = useState(false);
  const [addAttachment, setAddAttachment] = useState(false);
  const [addBookingAttachment, setAddBookingAttachment] = useState(false);
  var [attachments, setAttachments] = useState();
  var [bookingAttachments, setBookingAttachments] = useState();
  const [attachmentsLoaded, setAttachmentsLoaded] = useState(false);
  const [bookingAttachmentsLoaded, setBookingAttachmentsLoaded] = useState(false);
  const [uploadIdForDelete, setUploadIdForDelete] = useState('');
  const [bookingUploadIdForDelete, setBookingUploadIdForDelete] = useState('');
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [bookingAttachmentsOpen, setBookingAttachmentsOpen] = useState(false);
  const [createBooking, setCreateBooking] = useState(false);
  const [editBooking, setEditBooking] = useState(false);
  var [bookingList, setBookingList] = useState();
  const [bookingListLoaded, setBookingListLoaded] = useState(false);
  const [bookingListOpen, setBookingListOpen] = useState(false);
  var [pastBookingList, setPastBookingList] = useState();
  const [pastBookingListLoaded, setPastBookingListLoaded] = useState(false);
  const [pastBookingListOpen, setPastBookingListOpen] = useState(false);


  function clearAddAttachment() {
    setAddAttachment(false);
  }
  function clearAddBookingAttachment() {
    setAddBookingAttachment(false);
  }

  useEffect(()=> {
    console.log('Item ',item);
    console.log('Edit State:',editing);
    console.log('Attachments: ',attachments)
    console.log('attachmentsOpen: ',attachmentsOpen)
    console.log('attachmentsLoaded: ',attachmentsLoaded)
    console.log('bookingListLoaded',bookingListLoaded)
    console.log('pastBookingListLoaded',pastBookingListLoaded)
    console.log('bookingAttachmentsLoaded',bookingAttachmentsLoaded)
  });
  
  const lookUpItemNo = async () => {
      const url = baseUrl + 'item/' + itemNoUrl;
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
      .then((response) => response.json())
      .then((data) => {
         setItem(data[0]);
         setBookingView(false);
      })
      .catch((err) => {
         console.log(err.message);
      });
  };

  const lookUpBooking = async () => {
    const url = baseUrl + 'hire/' + bookingUrl;
    await fetch(url, {
      method: 'GET',
      headers: {
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      }})
    .then((response) => response.json())
    .then((data) => {
       setItem(data[0]);
       setBookingView(true);
    })
    .catch((err) => {
       console.log(err.message);
    });
};

  useEffect(() => {
    if(itemNoUrl !== null) {
      lookUpItemNo()
    }
  }, []);

  useEffect(() => {
    if(bookingUrl !== null) {
      lookUpBooking()
    }
  }, []);

  function showAttachments() {
      const url = baseUrl + 'itemAttachmentList/' + item.itemNo;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
           setAttachments(json);
           setAttachmentsLoaded(true);
           setAttachmentsOpen(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function showBookingAttachments() {
        const url = baseUrl + 'bookingAttachmentList/' + item.id;
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
             setBookingAttachments(json);
             setBookingAttachmentsLoaded(true);
             setBookingAttachmentsOpen(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }

    function showBookings() {
        const url = baseUrl + 'hire/list/' + item.itemNo;
        fetch(url, {
          method: 'GET',
          headers: {
              'username': localStorage.getItem('username'),
              'bToken': localStorage.getItem('bToken')
          }})
          .then((res) => {
            return res.json();
        }).then((json) => {
             setBookingList(json);
             setBookingListLoaded(true);
             setBookingListOpen(true);
        })
        .catch((err) => {
           console.log(err.message);
        });
      }

      function showPastBookings() {
          const url = baseUrl + 'hire/past/list/' + item.itemNo;
          fetch(url, {
            method: 'GET',
            headers: {
                'username': localStorage.getItem('username'),
                'bToken': localStorage.getItem('bToken')
            }})
            .then((res) => {
              return res.json();
          }).then((json) => {
               setPastBookingList(json);
               setPastBookingListLoaded(true);
               setPastBookingListOpen(true);
          })
          .catch((err) => {
             console.log(err.message);
          });
        }

    const [open, setOpen] = React.useState(false);
    const [bookingOpen, setBookingOpen] = React.useState(false);

    const handleClickOpen = uploadId => {
      setUploadIdForDelete(uploadId)
      setOpen(true);
    };
    const handleBookingClickOpen = bookingUploadId => {
      setBookingUploadIdForDelete(bookingUploadId)
      setBookingOpen(true);
    };

  const handleClose = () => {
    setUploadIdForDelete('')
    setOpen(false);
  };

  const handleBookingClose = () => {
    setBookingUploadIdForDelete('')
    setBookingOpen(false);
  };

    function delAttachment() {
      const url = baseUrl + 'uploadDel/itemAttachment';
      fetch(url, {
        method: 'POST',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken'),
            'uploadId': uploadIdForDelete
        }}) 
        .then((res) => {
          return res.json();
      }).then((json) => {
           setAttachmentsLoaded(false);
           setOpen(false)
           setAttachmentsOpen(false)
           setUploadIdForDelete('')
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function delBookingAttachment() {
      const url = baseUrl + 'uploadDel/bookingAttachment';
      fetch(url, {
        method: 'POST',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken'),
            'bookingUploadId': bookingUploadIdForDelete
        }}) 
        .then((res) => {
          return res.json();
      }).then((json) => {
           setBookingAttachmentsLoaded(false);
           setBookingOpen(false)
           setBookingAttachmentsOpen(false)
           setUploadIdForDelete('')
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

  return (
    
    <div className="itemView">
      <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            Are you sure you want to delete the Attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={delAttachment}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
      <React.Fragment>
      <Dialog
        open={bookingOpen}
        onClose={handleBookingClose}
      >
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the Booking Attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBookingClose}>Cancel</Button>
          <Button onClick={delBookingAttachment}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    {localStorage.getItem('username') !== null ? (
  null
): <div>
< Login />
</div>}

<br></br>

{!item && !editing && !editBooking && localStorage.getItem('adminFunctions') === '1' ? (
  <div>
    < Search />
    < Lookup />
  </div>
): null}


    { item && !editing && !createBooking && !editBooking ? (
      <div>  
      { !addBookingAttachment && bookingView ? (
         <TableContainer component={Paper}>
         <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
          <div>Booking No: {item.id} <br />For <br />{item.company}</div> <br />
           <TableBody>
            <TableRow className={item.status}><TableCell align="left">Status</TableCell><TableCell align="right">{item.status}</TableCell></TableRow>
             <TableRow><TableCell align="left">Delivery Date</TableCell><TableCell align="right">{item.startDate}</TableCell></TableRow>
             <TableRow><TableCell align="left">Return Date</TableCell><TableCell align="right">{item.returnDate}</TableCell></TableRow>
             <TableRow><TableCell align="left">PO Number</TableCell><TableCell align="right">{item.poNumber}</TableCell></TableRow>
             <TableRow><TableCell align="left">Invoice Number</TableCell><TableCell align="right">{item.invoiceNumber}</TableCell></TableRow>
             </TableBody>
         </Table>
       </TableContainer>  ):null}

        { item && !addAttachment && !addBookingAttachment ? (
 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableBody>
          <TableRow><TableCell align="left">Item Number</TableCell><TableCell align="right" className="ucase">{item.itemNo}</TableCell></TableRow>
          <TableRow><TableCell align="left">Name</TableCell><TableCell align="right">{item.name}</TableCell></TableRow>
          <TableRow><TableCell align="left">Cat No</TableCell><TableCell align="right">{item.catNo}</TableCell></TableRow>
          <TableRow><TableCell align="left">Build Date</TableCell><TableCell align="right">{item.buildDate}</TableCell></TableRow>
          <TableRow><TableCell align="left">Type</TableCell><TableCell align="right">{item.type}</TableCell></TableRow>
          <TableRow><TableCell align="left">Site Location</TableCell><TableCell align="right">{item.siteLocation}</TableCell></TableRow>
          <TableRow><TableCell align="left">Item</TableCell><TableCell align="right">{item.item}</TableCell></TableRow>
          <TableRow><TableCell align="left">Manufacturer</TableCell><TableCell align="right">{item.manufacturer}</TableCell></TableRow>
          <TableRow><TableCell align="left">Model</TableCell><TableCell align="right">{item.model}</TableCell></TableRow>
          <TableRow><TableCell align="left">Serial Number</TableCell><TableCell align="right">{item.serialNo}</TableCell></TableRow>
          <TableRow><TableCell align="left">Max Height</TableCell><TableCell align="right">{item.maxHeight}</TableCell></TableRow>
          <TableRow><TableCell align="left">Capacity</TableCell><TableCell align="right">{item.capacity}</TableCell></TableRow>
          <TableRow><TableCell align="left">Inspection Status</TableCell><TableCell align="right">{item.inspectionStatus}</TableCell></TableRow>
          <TableRow><TableCell align="left">Last Inspected Date</TableCell><TableCell align="right">{item.lastInspectionDate}</TableCell></TableRow>
          {localStorage.getItem('adminFunctions') === '1' && !bookingView ? ( <TableRow><TableCell align="center"><a href={'https://asset.1maintenance.com.au/?catNo='+item.linkedCatNo}>Go to Inspection Site for Item</a></TableCell></TableRow> ):null}
          </TableBody>
      </Table>
    </TableContainer>
    ):null}
{ !addAttachment && !addBookingAttachment && item ? (
<div className="mb-2">
<br />
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableHead>
          <TableCell align="center">
          Attachments
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => !attachmentsOpen ? showAttachments() : setAttachmentsOpen(false)}
          >
            {attachmentsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          </TableCell>
        </TableHead>
        { attachmentsOpen ?(
      <TableBody>
                      {attachments.map((list, index) => (
                        <TableRow key={index}>
                            <TableCell align="center"><a href={list.attachmentUrl}>{list.attachmentName}</a>  { localStorage.getItem('adminFunctions') === '1' ? (<IconButton margin="dense" variant="contained" onClick = {() => {handleClickOpen(list.uploadId)}} ><Tooltip title="Delete Attachment"><CancelIcon /></Tooltip></IconButton>): null}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody> ):null}
      </Table>
    </TableContainer>
      </div>
): null}
{ !addAttachment && !addBookingAttachment && item && bookingView && localStorage.getItem('adminFunctions') === '1' ? (
<div className="mb-2">
<br />
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableHead>
          <TableCell align="center">
          Booking Attachments
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => !bookingAttachmentsOpen ? showBookingAttachments() : setBookingAttachmentsOpen(false)}
          >
            {bookingAttachmentsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          </TableCell>
        </TableHead>
        { bookingAttachmentsOpen ?(
      <TableBody>
                      {bookingAttachments.map((list, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{list.status} | <a href={list.attachmentUrl}>{list.attachmentName}</a> <IconButton margin="dense" variant="contained" onClick = {() => {handleBookingClickOpen(list.bookingId)}} ><Tooltip title="Delete Attachment"><CancelIcon /></Tooltip></IconButton></TableCell>
                        </TableRow>
                      ))}
                    </TableBody> ):null}
      </Table>
    </TableContainer>
      </div>
): null}

{ !addAttachment && !addBookingAttachment && !bookingView && item && localStorage.getItem('adminFunctions') === '1' ? (
<div className="mb-2">
<br />
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableHead>
          <TableCell align="center">
          Upcoming Bookings
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => !bookingListOpen ? showBookings() : setBookingListOpen(false)}
          >
            {bookingListOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          </TableCell>
        </TableHead>
        { bookingListOpen ?(
          
      <TableBody>
                      {bookingList.map((list, index) => (
                        <TableRow key={index}>
                            <TableCell align="center" className={list.status} onClick={(e) => {window.location.href='?booking='+list.hyperlink}}>{list.status} | {list.fullName} - {list.startDate} to {list.returnDate}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody> ):null}
      </Table>
    </TableContainer>
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableHead>
          <TableCell align="center">
          Past Bookings
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => !pastBookingListOpen ? showPastBookings() : setPastBookingListOpen(false)}
          >
            {pastBookingListOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          </TableCell>
        </TableHead>
        { pastBookingListOpen ?(
          
      <TableBody>
                      {pastBookingList.map((list, index) => (
                        <TableRow key={index}>
                            <TableCell align="center" className={list.status} onClick={(e) => {window.location.href='?booking='+list.hyperlink}}>{list.status} | {list.fullName} - {list.startDate} to {list.returnDate}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody> ):null}
      </Table>
    </TableContainer>
      </div>
): null}

{ !addAttachment && !addBookingAttachment && item && !bookingView && localStorage.getItem('adminFunctions') === '1' ? (
  <div className="mb-2">
                    <Button
                        margin="dense"
                        variant="contained"
                        onClick={(e) => {
                          setCreateBooking(true);
                      }}
                    >Create Booking
                    </Button>
  </div>
  ): null}

{ !addAttachment && !addBookingAttachment && item && !bookingView && localStorage.getItem('adminFunctions') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    setEditing(true);
                                }}
                            >
                                Edit Item
                            </Button>
          </div>
): null}
{ !addAttachment && !addBookingAttachment && item && !bookingView && localStorage.getItem('adminFunctions') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    setAddAttachment(true);
                                }}
                            >
                                Add Attachment
                            </Button>
                           
          </div>
): null}
{ !addAttachment && !addBookingAttachment && item && bookingView && localStorage.getItem('adminFunctions') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    setAddBookingAttachment(true);
                                }}
                            >
                                Add Booking Image
                            </Button>
                           
          </div>
): null}
{ !addAttachment && !addBookingAttachment && item && bookingView && localStorage.getItem('adminFunctions') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    setEditBooking(true);
                                }}
                            >
                                Edit Booking
                            </Button>
                           
          </div>
): null}
 {addAttachment && item ? (
  <div className="modalOuter">
  <div className="modalBox">
      <div className="outlet">
        <br />
  <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearAddAttachment}>Cancel Upload</Button>
  <FirstTab  foundItem={item}/>
          </div>
        </div>
      </div>
 ): null}
 {addBookingAttachment && item ? (
  <div className="modalOuter">
  <div className="modalBox">
      <div className="outlet">
        <br />
  <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearAddBookingAttachment}>Cancel Upload</Button>
  <SecondTab  foundItem={item}/>
          </div>
        </div>
      </div>
 ): null}
 
{ !addAttachment && !addBookingAttachment && item && !bookingView ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    window.location.href='/'
                                }}
                            >Return to Search
                            </Button>
          </div>
          ): null}
 
{ !addAttachment && !addBookingAttachment && item && bookingView && localStorage.getItem('adminFunctions') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                onClick={(e) => {
                                    window.location.href='/?item='+item.itemNo
                                }}
                            >Return to Item
                            </Button>
          </div>
          ): null}
          

  
  </div>
    ): null}

{ !addAttachment && !addBookingAttachment && editing && localStorage.getItem('adminFunctions') === '1' ? (        
<Edit 
foundItem={item}/>
): null}

{ !addAttachment && !addBookingAttachment && editBooking && localStorage.getItem('adminFunctions') === '1' ? (        
<EditBooking 
bookingItem={item}/>
): null}

{ !addAttachment && !addBookingAttachment && createBooking && localStorage.getItem('adminFunctions') === '1' ? (        
<CreateBooking 
bookingItem={item.itemNo}/>
): null}
<br />
<br />
  </div>
);
}
export default ItemView;