import React from "react";
import { useState } from "react";
import { baseUrl } from '../shared';
import '../pages/App.css';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

export function Lookup() {
  const [types, setTypes] = useState();
  const [typesShown, setTypesShown] = useState(false)
  const [selectedType, setSelectedType] = useState();
  const [typeSelected, setTypeSelected] = useState(false)

  const showItemTypes = async () => {
    const url = baseUrl + 'items/types/';
    await fetch(url, {
      method: 'GET',
      headers: {
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken')
      }})
      .then((res) => {
        return res.json();
    }).then((json) => {
         setTypes(json);
         setTypesShown(true);
         setTypeSelected(false);
    })
    .catch((err) => {
       console.log(err.message);
    });
  }

  async function showSelectedItemTypes(e) {
    e.preventDefault();
    const url = baseUrl + 'items/type/';
    await fetch(url, {
      method: 'GET',
      headers: {
          'username': localStorage.getItem('username'),
          'bToken': localStorage.getItem('bToken'),
          'itemType': e.target.innerText ,
      }})
      .then((res) => {
        return res.json();
    }).then((json) => {
         setSelectedType(json);
         setTypesShown(false);
         setTypeSelected(true);
    })
    .catch((err) => {
       console.log(err.message);
    });
  }

    return (
<>
<br />
{ !typesShown && !typeSelected ? (
<Button variant="contained" onClick={ showItemTypes }>Search By Item Type</Button>
  ):null}

<br />
{ typesShown && !typeSelected ? (
  <TableContainer>
    <Table aria-label="simple table" margin="dense">
      <TableBody>
        <TableRow><p style={{fontFamily: 'Lato', fontSize: "18px", color: "#00519C" }} align="center">Search By Item Type</p></TableRow>
        {types.map((types, index) => (
           <TableRow key={index}>
            <TableCell align="center"><Button sx={{ minWidth: 250}} variant="contained" onClick={(e) =>{ showSelectedItemTypes(e, types.type) }}>{types.type}</Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
  </Table>
</TableContainer>  ):null}
{ !typesShown && typeSelected ? (<Button margin="dense" variant="contained" onClick={ showItemTypes }>Return to Type Selection</Button>):null}
{ !typesShown && typeSelected ? (
  <TableContainer>
    <Table aria-label="simple table" margin="dense">
      <TableBody>
        <TableRow><p style={{fontFamily: 'Lato', fontSize: "18px", color: "#00519C" }} align="center">Click on Item to view</p></TableRow>
        {selectedType.map((selectedType, index) => (
           <TableRow key={index}>
            <TableCell align="center" className={selectedType.status} onClick={(e) => {window.location.href='?item='+selectedType.itemNo}}>Item: {selectedType.name} [ CatNo: {selectedType.catNo} ] Status: {selectedType.status} {(selectedType.company === null ? 'Available' : '[ Company:' +selectedType.company+']')}</TableCell>
          </TableRow>
        ))}
      </TableBody>
  </Table>
</TableContainer>  ):null}
</>
  )}

export default Lookup